// ResultCard.js
import React from 'react';
import { Card, Label, Icon, Table, Popup, Divider, Modal } from 'semantic-ui-react';

const ResultCard = ({result, handleTagClick, currentText, setCurrentText, style, query}) => {
const affiliate_eBay = "&mkcid=1&mkrid=710-53481-19255-0&siteid=3&campid=5339001794&customid=&toolid=10001&mkevt=1"

    return (
        <div style={{ ...style, margin: '25px', width: '90%', display: 'flex', justifyContent: 'left', paddingTop: '4em' }}>
            <Card fluid key={result._id} style={{minHeight: '600px', width: '70%', margin: '20px', boxShadow: '0px 8px 16px rgba(0,0,0,0.2)'}}>

                <Card.Content extra style={{ padding: '20px', backgroundColor: '#ffffff'}}>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-start' }}>

                        <Card.Header>
                            <header style={{ color: '#827270', fontSize: '24px' }}>{result.year}</header>
                            <br/>
                            <header style={{ color: '#494542', fontSize: '26px', lineHeight: '1.5' }}> {result.main_title.toUpperCase()} </header>
                        </Card.Header>
                       

                    </div>
                    <Card.Meta>
                        {'by'}
                        {result.author ?
                            result.author.map((author, index) => {
                                const isAuthorActive = query.includes(`author="${author}"`);
                                return (
                                    <a 
                                        key={index}
                                        onClick={() => handleTagClick('author', {value: author})}
                                        style={{ 
                                            fontSize: '18px',
                                            color: isAuthorActive ? '#B04949' : '#0178C2',
                                            margin: '5px 5px'
                                        }} 
                                    >
                                        {author} {isAuthorActive && <Icon name='delete' />}
                                    </a>
                                )
                            }) 
                        : 
                            'Unknown'
                        }
                    </Card.Meta> 
                    </Card.Content>   
                    <Card.Content extra style={{ backgroundColor: '#ffffff'}}>

                    {result.category && result.category.map((category, index) => {           
                        const isCategoryActive = query.includes(`category="${category}"`);
                        return (
                            <Label 
                                key={index}
                                as='a'
                                onClick={() => handleTagClick('category', {value: category})}
                                style={{ 
                                    backgroundColor: isCategoryActive ? '#B04949' : '#E4E2DC',
                                    color: isCategoryActive ? '#FFFFFF' : '#6C6962',
                                    margin: '5px 5px'
                                }} 
                            >
                                {category}
                                {isCategoryActive && <Icon name='delete' />}
                            </Label>
                        )
                    })}

                    {result.subcategory && result.subcategory.map((subcategory, index) => {          
                        const isSubcategoryActive = query.includes(`subcategory="${subcategory}"`);
                        return (
                            <Label 
                                key={index}
                                as='a'
                                onClick={() => handleTagClick('subcategory', {value: subcategory})}
                                style={{ 
                                    backgroundColor: isSubcategoryActive ? '#B04949' : '#E4E2DC',
                                    color: isSubcategoryActive ? '#FFFFFF' : '#6C6962',
                                    margin: '5px 5px'
                                }} 
                            >
                                {subcategory}
                                {isSubcategoryActive && <Icon name='delete' />}
                            </Label>
                        )
                    })} 
                
                    {result.contributors && result.contributors.map((contributor, index) => {
                        const isContributorActive = query.includes(`contributors="${contributor.name}"`);
                        return (
                            <Label image
                                key={index}
                                as='a'
                                onClick={() => handleTagClick('contributors', {value: contributor.name})}
                                style={{ 
                                    backgroundColor: isContributorActive ? '#B04949' : '#E4E2DC',
                                    color: isContributorActive ? '#FFFFFF' : '#6C6962',
                                    margin: '5px 5px'
                                }} 
                            >
                                <Icon name='user' />{contributor.name}
                                {isContributorActive && <Icon name='delete' />}
                                <Label.Detail style={{ 
                                    backgroundColor: isContributorActive ? '#B04949' : '#E4E2DC',
                                    color: isContributorActive ? '#FFFFFF' : '#6C6962',
                                }}>
                                    {contributor.role}
                                </Label.Detail>
                            </Label>
                        )
                    })}
          
                    {result.printer && result.printer.map((printer, index) => {              
                        const isPrinterActive = query.includes(`printer="${printer}"`);
                        return (
                        <Label image
                            style={{ 
                                backgroundColor: isPrinterActive ? '#B04949' : '#E4E2DC',
                                color: isPrinterActive ? '#FFFFFF' : '#6C6962',
                                margin: '5px 5px'
                            }} 
                            key={index}
                            as='a'
                            onClick={() => handleTagClick('printer', {value: printer})}
                        >
                            {printer}
                            {isPrinterActive && <Icon name='delete' />}
                            <Label.Detail 
                                style={{ 
                                    backgroundColor: isPrinterActive ? '#B04949' : '#E4E2DC',
                                    color: isPrinterActive ? '#FFFFFF' : '#6C6962',
                                }}
                            >Printer
                            </Label.Detail>                       
                        </Label>
                        )
                    })}       

                    {result.binder && result.binder.map((binder, index) => {              
                        const isBinderActive = query.includes(`binder="${binder}"`);
                        return (
                        <Label image
                            key={index}
                            as='a'
                            onClick={() => handleTagClick('binder', {value: binder})}
                            style={{ 
                                backgroundColor: isBinderActive ? '#B04949' : '#E4E2DC',
                                color: isBinderActive ? '#FFFFFF' : '#6C6962',
                                margin: '5px 5px'
                            }} 
                        >                          
                            {binder}
                            {isBinderActive && <Icon name='delete' />}
                            <Label.Detail 
                                style={{ 
                                    backgroundColor: isBinderActive ? '#B04949' : '#E4E2DC',
                                    color: isBinderActive ? '#FFFFFF' : '#6C6962',
                                }}
                            >Binder
                            </Label.Detail>
                            
                        </Label>
                        )
                    })}
                        </Card.Content>   
                    
                    <Card.Content style={{padding: '5px', backgroundColor: '#Ffffff'}}>
                    <div style={{width: '100%',backgroundColor: '#Ffffff', maxHeight: '300px', borderRadius: '15px', overflow: 'auto', paddingLeft: '15px', paddingRight: '25px', paddingTop: '0px', paddingBottom: '0px'}}>
                        <Table basic='very'>
                            
                            <Table.Body>
                                {typeof result.limitation_details === 'string' && 
                                <Table.Row>
                                    <Table.Cell style={{ width: '50px' }}><Icon style={{ color: '#0178C2' }} size='large' name='gem outline'></Icon></Table.Cell>
                                    <Table.Cell style={{ fontSize: '14px', color: '#494542' }}>{result.limitation_details}</Table.Cell>
                                </Table.Row>
                                }
                                {typeof result.binding_details === 'string' && 
                                <Table.Row>
                                    <Table.Cell style={{ width: '50px' }}><Icon style={{ color: '#A9A59B' }} size='large' name='square full'></Icon></Table.Cell>
                                    <Table.Cell style={{ fontSize: '14px', color: '#494542' }}>{result.binding_details}</Table.Cell>
                                </Table.Row>
                                }
                                {typeof result.image_details === 'string' && 
                                <Table.Row>
                                    <Table.Cell style={{ width: '50px' }} ><Icon style={{ color: '#A9A59B' }} size='large' name='paint brush'></Icon></Table.Cell>
                                    <Table.Cell style={{ fontSize: '14px', color: '#494542' }}>{result.image_details}</Table.Cell>
                                </Table.Row>
                                }
                                {typeof result.cover_details === 'string' && 
                                <Table.Row>
                                    <Table.Cell style={{ width: '50px' }}><Icon style={{ color: '#A9A59B' }} size='large' name='shield'></Icon></Table.Cell>
                                    <Table.Cell style={{ fontSize: '14px', color: '#494542' }}>{result.cover_details}</Table.Cell>
                                </Table.Row>
                                }
                                {typeof result.interesting_facts === 'string' && 
                                <Table.Row>
                                    <Table.Cell style={{ width: '50px' }}><Icon style={{ color: '#A9A59B' }} size='large' name='lightbulb outline'></Icon></Table.Cell>
                                    <Table.Cell style={{ fontSize: '14px', color: '#494542' }}>{result.interesting_facts}</Table.Cell>
                                </Table.Row>
                                }
                            </Table.Body>
                        </Table>
                    </div> 
                    </Card.Content>
               
                <Card.Content extra >
                    
                        {(result.reprint_count > 0) &&
                            <Modal 
                                size='large'
                                trigger={
                                    <Label 
                                        style={{margin: '5px 5px', backgroundColor: '#F1EDEA'}}
                                        as='a' 
                                        className="ui image label" 
                                    >
                                        <Icon name='redo' /> Reprints
                                        <Label.Detail style={{backgroundColor: '#E4E2DC'}}>{result.reprint_count}</Label.Detail>
                                    </Label>
                                }
                            >
                                <Modal.Header>FS {result.item}: {result.main_title} ({result.year}) </Modal.Header>
                                <Modal.Content scrolling>
                                    <div style={{ minWidth: '750px' }}>{
                                        Array.isArray(result.reprint_details) && result.reprint_details.length > 0 ?
                                        <Table celled>
                                            <Table.Header>
                                                <Table.Row >
                                                    <Table.HeaderCell style={{ backgroundColor: '#F1EDEA' }}>Name</Table.HeaderCell>
                                                    <Table.HeaderCell style={{ backgroundColor: '#F1EDEA' }}>Year</Table.HeaderCell>
                                                    <Table.HeaderCell style={{ backgroundColor: '#F1EDEA' }}>Details</Table.HeaderCell>
                                                </Table.Row>
                                            </Table.Header>
                                            <Table.Body>
                                                {result.reprint_details.map((reprint, index) => (
                                                    <Table.Row key={index}>
                                                        <Table.Cell>{reprint.name}</Table.Cell>
                                                        <Table.Cell>{reprint.year}</Table.Cell>
                                                        <Table.Cell>{reprint.details}</Table.Cell>
                                                    </Table.Row>
                                                ))}
                                            </Table.Body>
                                        </Table>

                                        : null
                                    } </div>
                                </Modal.Content>
                            </Modal>
                            }

                            <Popup
                            trigger={
                                <Label
                                style={{ margin: '5px 5px', backgroundColor: '#F1EDEA' }}
                                as='a'
                                className="ui image label"
                                onClick={() => setCurrentText(result.text)} 
                                >
                                FS<Label.Detail style={{ backgroundColor: '#E4E2DC' }}>{result.item}</Label.Detail>
                                </Label>
                            }
                            position='bottom left'
                            on='click' 
                            open={currentText === result.text}
                            onClose={() => setCurrentText(null)}
                            content={
                                <div style={{ minWidth: '600px', color: '#494542' }}>
                                    {currentText === result.text ? currentText : null} 
                                    <Divider></Divider>
                                    Source: <b>Folio 75 (1947-2022)</b>                          
                                </div>
                            }
                            />
                    <Label style={{margin: '5px 5px', backgroundColor: '#E4E2DC', color: '#6C6962'}} as='a' onClick={() => window.open(`https://www.ebay.com/sch/i.html?_nkw=${encodeURIComponent('Folio Society ' + result.year + ' ' + result.main_title)}${affiliate_eBay}`, '_blank')}>
                        eBay
                        <Label.Detail><i className="us flag"></i></Label.Detail> 
                    </Label>
                    <Label style={{margin: '5px 5px', backgroundColor: '#E4E2DC', color: '#6C6962'}} as='a' onClick={() => window.open(`https://www.ebay.co.uk/sch/i.html?_nkw=${encodeURIComponent('Folio Society ' + result.year + ' ' + result.main_title)}${affiliate_eBay}`, '_blank')}>
                        eBay 
                        <Label.Detail><i className="uk flag"></i> </Label.Detail> 
                    </Label>  
                    <Label style={{margin: '5px 5px', backgroundColor: '#E4E2DC', color: '#6C6962'}} as='a' onClick={() => window.open(`https://www.abebooks.com/servlet/SearchResults?kn=${encodeURIComponent('Folio Society ' + result.year + ' ' + result.main_title)}`, '_blank')}>
                        AbeBooks
                        <Label.Detail><i className="us flag"></i> </Label.Detail>  
                    </Label> 
                    <Label style={{margin: '5px 5px', backgroundColor: '#E4E2DC', color: '#6C6962'}} as='a' onClick={() => window.open(`https://www.abebooks.co.uk/servlet/SearchResults?kn=${encodeURIComponent('Folio Society ' + result.year + ' ' + result.main_title)}`, '_blank')}>
                        AbeBooks
                        <Label.Detail><i className="uk flag"></i> </Label.Detail>  
                    </Label>
                </Card.Content>

                <Card.Content extra style={{backgroundColor: '#F7F5F3'}}>
                
                    {Number.isInteger(result.volumes) &&
                    <Label style={{margin: '5px 5px', backgroundColor: '#F7F5F3'}}>
                        <Icon name='book' />Volumes
                        <Label.Detail>{result.volumes}</Label.Detail>
                    </Label>
                    }
                    {Number.isInteger(result.pages) &&
                    <Label style={{margin: '5px 5px', backgroundColor: '#F7F5F3'}}>
                        <Icon name='file text outline' />Pages
                        <Label.Detail>{result.pages}</Label.Detail>
                    </Label>
                    }
                    {Number.isInteger(result.image_count) &&
                            <Label style={{margin: '5px 5px', backgroundColor: '#F7F5F3'}}>
                                <Icon name='image' />Images
                                <Label.Detail>{result.image_count}</Label.Detail>
                            </Label>
                    }
                    {typeof result.type === 'string' && 
                        <Label style={{margin: '5px 5px', backgroundColor: '#F7F5F3'}}>
                        <Icon name='arrows alternate vertical' />H x W x D
                        <Label.Detail>{result.dimensions}</Label.Detail>
                    </Label>}
                        {typeof result.type === 'string' && 
                        <Label style={{margin: '5px 5px', backgroundColor: '#F7F5F3'}}>
                        <Icon name='font' />Type
                        <Label.Detail>{result.type}</Label.Detail>
                    </Label>}
         
               </Card.Content>
            </Card>
        </div>
    );
};

export default ResultCard;
