import React from 'react';
import { Menu, Image, Button, Icon, Header, Statistic, Loader } from 'semantic-ui-react';
import SearchInput from './SearchInput'; 
import logo from './logo.png';

const TopMenu = ({
  numberOfResults,
  inputValue,
  handleSearch,
  resetSearch,
  toggleSortOrder,
  sortOptions,
  setIsSorting,
  sortBy,
  results,
  setResults,
  setSortField,
  setSortBy,
  setPage,
  setShouldPerformSearch,
  sortField,
  isSearching,
  setYearQuery
}) => {
    
  return (

    <Menu fixed='top' style={{ height: '75px', backgroundColor: '#E4E2DC'}}>
          
         
          <Image src={logo} alt='Logo' style={{ width: '74px', height: '74px' }} />
            <Menu.Item style={{ backgroundColor: '#FBFBFB'}}>

       <span style={{ width: '1em'}}></span>
        <Statistic size='tiny' style={{ margin: '0px', width: '6em'}}>
          <Statistic.Value style={{ color: '#827270', lineHeight: '1.2' }}>
            {isSearching ? <Loader active inline size='medium' /> : numberOfResults}
          </Statistic.Value>
          <Statistic.Label style={{ color: '#A9A59B' }}>{isSearching ? '' : 'books'}</Statistic.Label>
        </Statistic>
        
        <span style={{ width: '1em'}}></span>
        
      </Menu.Item>
      
      <Menu.Item style={{ backgroundColor: '#E4E2DC'}}>
        <span style={{ width: '1em'}}></span>
        
        <SearchInput onSelect={handleSearch} setYearQuery={setYearQuery} />
        <span style={{ width: '1em'}}></span>
      </Menu.Item>
      <Menu.Item style={{ backgroundColor: '#E4E2DC'}}>
      <span style={{ width: '1em'}}></span>
        <Button.Group basic>
          <Button onClick={resetSearch}><Icon name='x' /> Reset Filters</Button>      
          <Button style={{ backgroundColor: '#FBFBFB', color: '#6C6962' }} onClick={toggleSortOrder}>
            <Icon name='sort' />Sort Order
          </Button>            
        </Button.Group>

        <span style={{ width: '1em'}}></span>  
         
      </Menu.Item>
     
    </Menu>
  );
};

export default TopMenu;
