import React, { useState, useEffect } from 'react';
import { Dropdown, Header } from 'semantic-ui-react';
import { debounce } from 'lodash';


const SearchInput = ({ onSelect, setYearQuery }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [error, setError] = useState(null);
  const [dropdownValue, setDropdownValue] = useState(null);

  const fetchResults = debounce(async () => {
    try {
      setIsSearching(true);
      
      const response = await fetch(
        'https://91ul70r2p7.execute-api.us-east-1.amazonaws.com/prod', 
        // process.env.REACT_APP_API_AUTOCOMPLETE_URL,
        {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ query: searchTerm }),
      });

      const data = await response.json();
      const content = JSON.parse(data.body);

      setSearchResults(content);
      setError(null);
    } catch (err) {
      setError('An error occurred while searching. Please try again.');
      setSearchResults([]);
    } finally {
      setIsSearching(false);
    }
  }, 500); // Debounce time in ms

  useEffect(() => {
    if (searchTerm.length >= 3) {
      fetchResults();
      
    } else {
      setSearchResults([]);

      setIsSearching(false);
    }
    
    // Clean up the debounce function when component unmounts
    return () => {
      fetchResults.cancel();
    };

  }, [searchTerm]);

  const handleSearchChange = (e, data) => {
    setSearchTerm(data.searchQuery);
    setDropdownValue(null);
  };

  function highlightText(fullText, query) {
    const parts = fullText.split(new RegExp(`(${query})`, 'gi'));
    return parts.map((part, i) => 
      part.toLowerCase() === query.toLowerCase() 
        ? <span key={i} style={{ backgroundColor: '#FFEED2', color: '#494542' }}>{part}</span> 
        : part
    );
  }
  
  const uniqueAuthors = new Set();
searchResults.forEach(result => {
  result.author.forEach(author => {
    uniqueAuthors.add(author);
  });
});

const authorOptions = [...uniqueAuthors].map((author, index) => ({
  key: `author_${index}`,
  value: `all_titles_by_${author}`,
  text: `All titles by ${author}`,
  type: 'author',
  author: author,
  content: (
    <Header as='h5' content={`All titles by ${author}`} />
  )
}));

  const sortedSearchResults = [...searchResults].sort((a, b) => b.year - a.year);

  const fullTitleOptions = sortedSearchResults.map((result, index) => ({
    key: `full_title_${index}`,
    value: result._id.$oid,
    text: `${result.main_title} by ${result.author} (${result.year})`,
    content: highlightText(`${result.main_title} by ${result.author} (${result.year})`, searchTerm),
    type: 'full_title',
    result: result,
  }));
  
  const options = [...authorOptions, ...fullTitleOptions];
  const handleSelectionChange = (e, data) => {
    const selectedOption = data.options.find(option => option.value === data.value);
    setSearchTerm('');
    setDropdownValue(data.value);
    
    if (selectedOption) {
      let newQuery = '';
      if (selectedOption.type === 'full_title') {
        newQuery = `item="${selectedOption.result.item}"`;
      } else if (selectedOption.type === 'author') {
        newQuery = `author="${selectedOption.author}"`;
      }
      setYearQuery('')

      onSelect(newQuery);
    }
  };

  return (
    <div>
      <Dropdown
        placeholder='Search by Title or Author...'
        style={{ width: '400px' }}
        fluid
        search
        selection
        onChange={handleSelectionChange}
        options={options}
        searchQuery={searchTerm}
        onSearchChange={handleSearchChange}
        loading={isSearching}
        value={dropdownValue}
        noResultsMessage={null}
        selectOnNavigation={false}
      />
      {error && <div>{error}</div>}
    </div>
  );
};

export default SearchInput;
