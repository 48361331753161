import React, { useState, useEffect, useRef } from 'react';
import { FixedSizeList as List } from 'react-window';
import { Card } from 'semantic-ui-react';
import ResultCard from './ResultCard'
import TopTags from './TopTags';
import TopMenu from './TopMenu';

const isHighEndMobile = window.innerWidth > 1000 && /iPhone|iPad|iPod/i.test(navigator.userAgent);

const Search = () => {
  const resultsRef = useRef();
  const [listHeight, setListHeight] = useState(0);
  
  const [inputValue, setInputValue] = useState('');
  const [query, setQuery] = useState('');
  const [yearQuery, setYearQuery] = useState('');
  const [shouldPerformSearch, setShouldPerformSearch] = useState(true);

  const [results, setResults] = useState([]);
  const [topTags, setTopTags] = useState(null);
  const [numberOfResults, setNumberOfResults] = useState(null);
  const [dateCounts, setDateCounts] = useState(null);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true); 
  const [sortField, setSortField] = useState('item_sort');

  const [loading, setLoading] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [isSorting, setIsSorting] = useState(false);
  const [sortBy, setSortBy] = useState({ field: sortField, order: 1 });

  const [open, setOpen] = useState(false);
  const [currentReprintDetails, setCurrentReprintDetails] = useState([]);
  const [openText, setOpenText] = useState(false);
  const [currentText, setCurrentText] = useState([]);

  // Function to update the list height
  const updateListHeight = () => {
    if (resultsRef.current) {
      setListHeight(resultsRef.current.offsetHeight + 10);
    }
  }
  // Run once on initial render to set the list height
  useEffect(() => {
    updateListHeight();
  }, []);
  // Run when the window is resized
  useEffect(() => {
    window.addEventListener('resize', updateListHeight);
    return () => window.removeEventListener('resize', updateListHeight);
  }, []);

  const handleChange = (event) => {
    setInputValue(event.target.value); 
  } // handleChange

  const handleSearch = (inputValue) => {
    if (inputValue !== query) {
      setResults([]); 
      setPage(1); 
      setIsSearching(true); 
      setQuery(inputValue); 
      setShouldPerformSearch(true);
    }
    
  } // handleSearch

  const toggleSortOrder = () => {
    setIsSorting(true);
  
    setSortBy((prevSortBy) => {
      const newSortBy = {
        field: prevSortBy.field,
        order: prevSortBy.order === 1 ? -1 : 1
      };
      if (results.length === numberOfResults) {
        const sortedResults = [...results].sort((a, b) => {
            if (typeof a[newSortBy.field] === "string") {
                if (newSortBy.order === 1) {
                    return a[newSortBy.field].localeCompare(b[newSortBy.field]);
                } else {
                    return b[newSortBy.field].localeCompare(a[newSortBy.field]);
                }
            } else {
                if (newSortBy.order === 1) {
                    return a[newSortBy.field] - b[newSortBy.field];
                } else {
                    return b[newSortBy.field] - a[newSortBy.field];
                }
            }
        });
        setResults([]);
        setTimeout(() => {
          setResults(sortedResults);
          setIsSorting(false);
        }, 100); // delay of 100ms
        setShouldPerformSearch(false);
        return newSortBy;
      } else {
        setPage(1);
        setShouldPerformSearch(true);
        return newSortBy;
      }
    });
  }; // toggleSortOrder

  const handleTagClick = (key, tag) => {
    setShouldPerformSearch(false)
    setIsSearching(true);
    setPage(1)
    setInputValue('')

    const tagString = `${key}="${tag.value}"`;
    const tagStringWithAnd = `AND ${tagString}`;

    setQuery(prevQuery => {
      // Treat the previous query as empty if it does not contain "="
      if (!prevQuery.includes('=')) {
        prevQuery = '';
      }
    
      let newQuery;
      if (prevQuery.includes(tagStringWithAnd)) {
        newQuery = prevQuery.replace(tagStringWithAnd, '').trim();
      } else if (prevQuery.includes(tagString)) {
        newQuery = prevQuery.replace(tagString, '').trim();
      } else {
        newQuery = prevQuery ? `${prevQuery} ${tagStringWithAnd}` : tagString;
      }
      if (newQuery.startsWith('AND ')) {
        newQuery = newQuery.substring(4);
      }
      setShouldPerformSearch(true);
      return newQuery;
    });
    
  }; // handleTagClick

  const handleDateClick = (startYear) => {
    setShouldPerformSearch(false)
    setIsSearching(true);
    setPage(1)

    const yearRange = `${startYear}:${startYear + 10}`;
  
    setYearQuery(prevYearQuery => {
      let newYearQuery;

      if (prevYearQuery === `year="${yearRange}"`) {
        setYearQuery(false)
      } else {
        setYearQuery(`year="${yearRange}"`)
      }

      setShouldPerformSearch(true)
      return newYearQuery;
    });
  }; // handleDateClick
  
  // performSearch
  const performSearch = async (page, newQuery = query, newSortBy = sortBy, newSortField = sortField) => { 
    const requestBody = newQuery ? { query: newQuery, page, sortBy: newSortBy } : { page, sortBy: newSortBy };
    setLoading(true);
    
    try {
      const response = await fetch(
        "https://asfazch9gd.execute-api.us-east-1.amazonaws.com/prod/",
        //process.env.REACT_APP_API_URL,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(requestBody),
        }
      );
      if (!response.ok) {
        throw new Error(`API request failed with status ${response.status}`);
      }

      const responseData = await response.json();
      // Debug: console.log("Call API")
      const newResults = JSON.parse(responseData.body);

      const pageSize = responseData.pageSize

      if (newResults.length < pageSize) {
        setHasMore(false);
      } else {
        setHasMore(true);
      }
      if (page > 1) {
        setResults(prevResults => [...prevResults, ...newResults]);
      } else {
        setResults(newResults);
        setTopTags(responseData.topTags)
        setNumberOfResults(responseData.numberOfResults)
        setDateCounts(responseData.countsByDate)
      }
    }
    catch (error) {
      console.error('API request failed:', error);
    }

    setIsSearching(false);
    setLoading(false);
    setIsSorting(false);
  } // performSearch

  const resetSearch = () => {
    const newQuery = '';
    const newYearQuery = '';
    const newPage = 1;
    const newSortField = 'item_sort';
    const newSortBy = { field: 'item_sort', order: 1 };
    setShouldPerformSearch(false)
    setQuery(newQuery); // Clear the query
    setYearQuery(newYearQuery); // Clear the year query
    setPage(newPage); // Reset the page number
    setSortField(newSortField);
    setSortBy(newSortBy); // Reset the sort order directly here
    setIsSearching(true); // Set isSearching to true
    setInputValue('')
    performSearch(newPage, newQuery, newSortBy, newSortField); // Fetch the initial results
  } // resetSearch
  
  useEffect(() => {   
    if (shouldPerformSearch) {
      setHasMore(true);
      // Combine the original query and the year query
      const finalQuery = [query, yearQuery].filter(Boolean).join(' | ');
      performSearch(page, finalQuery);
    }

  }, [query, page, sortBy, shouldPerformSearch, yearQuery, sortField]);

  const sortOptions = [
    { key: 'item_sort', value: 'item_sort', text: 'Date' },
    { key: 'image_count', value: 'image_count', text: 'Image count' },
    { key: 'reprint_count', value: 'reprint_count', text: 'Reprint count' },
    { key: 'volumes', value: 'volumes', text: 'Volumes' },
    { key: 'pages', value: 'pages', text: 'Pages' },
  ];

  return (
    <div className="container" >

      <div className="topMenu">
        <TopMenu 
          numberOfResults={numberOfResults}
          inputValue={inputValue}
          handleChange={handleChange}
          handleSearch={handleSearch}
          resetSearch={resetSearch}
          toggleSortOrder={toggleSortOrder}
          sortOptions={sortOptions}
          setIsSorting={setIsSorting}
          sortBy={sortBy}
          results={results}
          setResults={setResults}
          setSortField={setSortField}
          setSortBy={setSortBy}
          setPage={setPage}
          setShouldPerformSearch={setShouldPerformSearch}
          sortField={sortField}
          isSearching={isSearching}
          setYearQuery={setYearQuery}
        />
      </div>

      <div className="topTags">
        {!isHighEndMobile && (
          <TopTags 
            dateCounts={dateCounts} 
            topTags={topTags} 
            handleDateClick={handleDateClick} 
            handleTagClick={handleTagClick} 
            numberOfResults={numberOfResults}
            query={query}
            yearQuery={yearQuery}
            isSearching={isSearching}
          />
        )}
      </div>

      <div ref={resultsRef} className="results">
      
        {isSearching && <p><br/>Searching...</p>}
        {isSorting && <p><br/>Sorting...</p>}
        {!isSearching && !isSorting && (
          <Card.Group>

           <List
              height={listHeight}
              itemCount={results.length}
              itemSize={800}
              width='100%'
              onItemsRendered={({ overscanStopIndex }) => {
                if (!loading && hasMore && overscanStopIndex >= results.length - 75) {
                  setPage(prevPage => {
                    return prevPage + 1;
                  });
                }
              }}
            >
              {({ index, style }) => {  
                const result = results[index];
                return (
                    <ResultCard
                      style={style}
                      result={result}
                      handleTagClick={handleTagClick}
                      open={open}
                      setOpen={setOpen}
                      currentReprintDetails={currentReprintDetails}
                      setCurrentReprintDetails={setCurrentReprintDetails}
                      openText={openText}
                      setOpenText={setOpenText}
                      currentText={currentText}
                      setCurrentText={setCurrentText}
                      query={query}
                    />
                );
              }}
            </List>

          </Card.Group>
        )}
      </div>
      
    </div>
  );
}
export default Search;



