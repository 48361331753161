import React from 'react';
import { BarChart, XAxis, YAxis, Tooltip, Bar, ResponsiveContainer, Label as ChartLabel } from 'recharts';
import { Divider, Label, Icon, Header } from 'semantic-ui-react';

const TopTags = ({ dateCounts, topTags, handleDateClick, handleTagClick, numberOfResults, query, yearQuery, isSearching }) => {
  
  const sortedDateCounts = dateCounts ? [...dateCounts].sort((a, b) => a.value - b.value) : [];
  const contextRef = React.createRef();

  const displayYearRange = (yearQuery) => {
    const [startYear, endYear] = yearQuery.replace('year=', '').replace(/"/g, '').split(':');
    return `${startYear} - ${parseInt(endYear) - 1}`;
  };
  
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const startYear = label;
      const endYear = startYear + 9;
      return (
        <div style={{
          backgroundColor: '#FBF9F7',
          border: '1px solid #6C6962',
          borderRadius: '10px', // Rounded corners
          padding: '10px',
          color: '#494542' // Font color
        }}>
          <p style={{ margin: 0 }}>Year: {startYear} to {endYear}</p>
          <p style={{ margin: 0 }}>Books: {payload[0].value}</p>
        </div>
      );
    }
    return null;
  };
  
  return (
    <div id="topTagsContainer" ref={contextRef} style={{ flex: '1 0 20%', paddingTop: '5em', fontSize: '15px'}} className="tags">
      

        {!isSearching && (
            <>
           
                <div style={{ paddingLeft: '0px' }} className="dateCounts">     
                    <ResponsiveContainer width="100%" height={250}>
                        <BarChart data={sortedDateCounts}>
                            <XAxis tick={{fontSize: 12, fill: '#6C6962'}} axisLine={{ stroke: '#F7F5F3' }} dataKey="value">
                            </XAxis>
                            <YAxis tick={{fontSize: 12, fill: '#6C6962'}} axisLine={{ stroke: '#6C6962' }} orientation='right' allowDecimals={false}/>
                            <Tooltip content={<CustomTooltip />} /> {/* Custom Tooltip */}
                            <Bar 
                                dataKey="count"
                                fill="#D6DAE2"
                                stroke="#8AA0B8" // border color
                                strokeWidth={1} // border width
                                onClick={(data) => handleDateClick(data.payload.value)}
                            />
                        </BarChart>
                    </ResponsiveContainer>         
                </div>

                {(yearQuery || query) && <h3 style={{ color: '#494542' }}>ACTIVE FILTERS</h3>}
                {yearQuery && <Label style={{ backgroundColor: '#FFEED2', margin: '5px'}}>{displayYearRange(yearQuery)}</Label>}
                {query && <Label style={{ backgroundColor: '#FFEED2', }}>{query}</Label>}

                <h3 style={{ color: '#494542' }}>TOP FIELDS</h3>

                {topTags && Object.keys(topTags).map((key) => (
                    <div key={key}>
                        <Divider></Divider>
                        <h4 style={{ color: '#494542' }}>{key.toUpperCase()}</h4>
                        {topTags[key].map((tag, index) => {
                            const isTagActive = query.includes(`${key}="${tag.value}"`);
                            return (
                            <div key={index} style={{ paddingLeft: '20px', paddingBottom: '5px', fontSize: '14px'}}>
                                <a
                                    href="#"
                                    onClick={(e) => {
                                        handleTagClick(key, tag);
                                        e.preventDefault(); // prevent the default behavior of the link
                                        }}
                                    style={{
                                    color: isTagActive ? '#B04949' : '#0178C2'
                                    }}
                                >
                                    {tag.value}
                                    {isTagActive && <span> <Icon name='delete' /> </span>}
                                </a> ({tag.count})
                            </div>
                            );
                        })}
                    </div>
                ))}
            </>
        )}
        </div>
  );
};

export default TopTags;
